import type { AxiosError, AxiosRequestConfig } from 'axios';

import { INTL_DEFAULT_OPTIONS } from '~/utils/constant';
import type { IAxiosErrorResponseData } from '~/types/Error';

// 지정 스테이터스 코드
export const HTTP_STATUS_CODES = {
  unauthorized: 401,
};

export const CUSTOM_ERROR_CODES = {
  accessTokenExpired: 7,
};

// 비인증 오류 확인
// eslint-disable-next-line import/prefer-default-export
export function isUnauthorizedError(error) {
  if (!error) {
    return false;
  }
  return error.status === HTTP_STATUS_CODES.unauthorized;
}

export function isAccessTokenExpired(errorResponse: AxiosError['response']) {
  if (!errorResponse) {
    return false;
  }

  const { c, code } = errorResponse.data;

  return (
    c === CUSTOM_ERROR_CODES.accessTokenExpired || code === CUSTOM_ERROR_CODES.accessTokenExpired
  );
}

export function logAxiosRequest(request: AxiosRequestConfig) {
  const title = '[API Request Log]';
  const date = new Intl.DateTimeFormat('ko-KR', INTL_DEFAULT_OPTIONS).format(+new Date());

  console.log(title + ' date:', date);
  console.log(title + ' method:', request.method?.toUpperCase());
  console.log(title + ' url:', request.url);

  console.log(title + ' request headers:');
  console.table(request.headers.common);

  if (request.params) {
    console.log(title + ' request params:');
    if (typeof request.params === 'string') {
      console.log(request.params);
    }
    if (typeof request.params === 'object') {
      console.table(request.params);
    }
  }

  if (request.data) {
    console.log(title + ' request data:');
    if (typeof request.data === 'string') {
      console.log(request.params);
    }
    if (typeof request.data === 'object') {
      console.table(request.params);
    }
  }
}

export function logAxiosError(error: AxiosError<IAxiosErrorResponseData>) {
  const title = '[API Error Log]';
  const {
    config: { method, url },
  } = error;

  const date = new Intl.DateTimeFormat('ko-KR', INTL_DEFAULT_OPTIONS).format(+new Date());

  console.error(title + ' date:', date);
  console.error(title + ' method:', method?.toUpperCase());
  console.error(title + ' url:', url);

  if (error.response) {
    const { data, status, headers } = error.response;
    console.error(title + ' data:', data);
    console.error(title + ' status:', status);
    console.error(title + ' response headers:');
    console.table(headers);
  }

  if (error.request?.headers) {
    console.error(title + ' request headers:');
    console.table(error.request.headers);
  }

  console.error('--------------------------------------------------------------------------');
}
