import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import produce from 'immer';

import CONSTANT from '~/utils/constant';
import {
  cookie,
  isArrayBufferView,
  isNonAuthenticationRequest,
  isObject,
  isURLSearchParams,
} from '~/utils/common';
import { logAxiosError, logAxiosRequest } from '~/utils/api';

import type { IAxiosErrorResponseData } from '~/types/Error';

const { HEADERS } = CONSTANT;
const { HEADER_CLIENT_TYPE } = HEADERS;

const DUPLICATE_REQUEST_MSG_KEY = 'DUPLICATE_REQUEST';
const DUPLICATE_REQUEST_ERROR_MSG = '해당 요청을 처리 중입니다.';
const THROTTLER_DELAY_SECONDS = 2 * 1000;

const getCacheKey = (config: AxiosRequestConfig) => {
  const cacheConfig = {
    url: config.url,
    method: config.method,
    data: config.data,
    params: config.params,
  };

  if (isURLSearchParams(config.data)) {
    cacheConfig.data = config.data.toString();
  } else if (isArrayBufferView(config.data)) {
    cacheConfig.data = config.data.buffer;
  } else if (isObject(config.data)) {
    cacheConfig.data = JSON.stringify(config.data);
  }

  return JSON.stringify(cacheConfig);
};

/**
 * 내부 서버에 API 라우팅을 요청
 */
const fetcher = axios.create({
  timeout: 30000, // 30초
});

const onRequest = (request) => {
  if (!request.headers.common['powered-by-next']) {
    // Next.js에서 호출한 게 아니면(내부 호출) 베이스 URL을 제거한다.
    delete request.baseURL;
  }

  if (
    !request.headers.common['x-lg-app-version'] &&
    !request.headers.common[CONSTANT.HEADERS.HEADER_APP_VERSION] &&
    cookie.get(CONSTANT.COOKIE.APP_VERSION) !== undefined
  ) {
    request.headers.common[CONSTANT.HEADERS.HEADER_APP_VERSION] = cookie.get(
      CONSTANT.COOKIE.APP_VERSION
    );
  }

  if (request.headers.common.authorization) {
    request.headers.common[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] =
      request.headers.common.authorization;
  }

  if (isNonAuthenticationRequest(request)) {
    delete request.headers.authorization;
    delete request.headers.common.authorization;
    delete request.headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN];
    delete request.headers.common[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN];
  }

  const hasLgClientTypeHeader =
    request.headers.common[HEADER_CLIENT_TYPE] ||
    request.headers.common[HEADER_CLIENT_TYPE.toLowerCase()];

  if (!hasLgClientTypeHeader) {
    request.headers.common[CONSTANT.HEADERS.HEADER_CLIENT_TYPE] = 'WEB';
  }

  delete request.headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN_LOWERCASE];
  delete request.headers.common[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN_LOWERCASE];

  logAxiosRequest(request);

  return request;
};

const onResponse = (response) => {
  //   return response.data.data
  //     ? response
  //     : {
  //         ...response,
  //         data: {
  //           code: response.data.c,
  //           data: {
  //             ...response.data.d,
  //           },
  //           timestamp: response.data.ts,
  //           message: response.data.m || null,
  //         },
  //       };

  return response;
};

const onError = (error: AxiosError<IAxiosErrorResponseData>) => {
  logAxiosError(error);

  return Promise.reject(error);
};

const requestCache = new Set<string>();

const throttleOnRequest = (
  onRequestInterceptor: (config: AxiosRequestConfig) => AxiosRequestConfig,
  delay: number
) => {
  return (config: AxiosRequestConfig) => {
    const throttledMethods = ['post', 'put', 'patch', 'delete'];

    if (config.method && !throttledMethods.includes(config.method)) {
      return onRequestInterceptor(config);
    }

    const cacheKey = getCacheKey(config);

    if (requestCache.has(cacheKey)) {
      // eslint-disable-next-line no-console
      console.log('Duplicate request throttler activated');
      throw new axios.Cancel(
        `${DUPLICATE_REQUEST_MSG_KEY} - Duplicate request in ${delay / 1000} seconds`
      );
    }

    requestCache.add(cacheKey);
    setTimeout(() => {
      requestCache.delete(cacheKey);
    }, delay);

    return onRequestInterceptor(config);
  };
};

export const throttleOnResponse = (onResponseInterceptor) => {
  return (response) => {
    const cacheKey = getCacheKey(response.config);
    requestCache.delete(cacheKey);

    return onResponseInterceptor(response);
  };
};

const throttleOnError = (onErrorInterceptor: (err: AxiosError) => Promise<never>) => {
  return (error: AxiosError) => {
    const isDuplicateError =
      axios.isCancel(error) && error.message.includes(DUPLICATE_REQUEST_MSG_KEY);

    if (!isDuplicateError) {
      const cacheKey = getCacheKey(error.config);
      requestCache.delete(cacheKey);

      return onErrorInterceptor(error as any);
    }

    const errorMessage = DUPLICATE_REQUEST_ERROR_MSG;
    const duplicateError = new Error(errorMessage) as any;
    duplicateError.response = {
      data: {
        message: errorMessage,
        m: errorMessage,
      },
    };

    return onErrorInterceptor(duplicateError);
  };
};

fetcher.interceptors.request.use(throttleOnRequest(onRequest, THROTTLER_DELAY_SECONDS));
fetcher.interceptors.response.use(throttleOnResponse(onResponse), throttleOnError(onError));

// axios 인스턴스의 헤더 설정
export const setHeaders = (headers, isServer = false) => {
  const headersAppVersion =
    headers[CONSTANT.HEADERS.HEADER_APP_VERSION] || cookie.get(CONSTANT.COOKIE.APP_VERSION);

  // 기본 헤더 설정
  fetcher.defaults.headers.common = produce(headers, (draft) => {
    // CORS에 영향을 주는 헤더를 제거한다.
    delete draft['accept-encoding'];
    delete draft.connection;
    delete draft.host;
    delete draft.referer;

    // Next.js에서 호출됐는지 기록한다.
    draft['powered-by-next'] = isServer;

    if (headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] !== undefined) {
      draft[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] = headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN];
    }

    if (headers['device-id'] !== undefined) {
      draft['device-Id'] = headers['device-id'];
    }

    if (headers['user-agent'] !== undefined) {
      draft['user-agent'] = headers['user-agent'];
    }

    if (headers['muser-agent'] !== undefined) {
      draft['muser-agent'] = headers['muser-agent'];
    }

    if (headersAppVersion !== undefined) {
      draft[CONSTANT.HEADERS.HEADER_APP_VERSION] = headersAppVersion;
    }
  });
};

// axios 인스턴스의 헤더 설정
export const setAuthzationHeaders = (headers, isServer = false) => {
  // 기본 헤더 설정
  fetcher.defaults.headers.common = produce(headers, (draft) => {
    // CORS에 영향을 주는 헤더를 제거한다.
    delete draft['accept-encoding'];
    delete draft.connection;
    delete draft.host;
    delete draft.referer;
    delete draft.cookie;
    delete draft['sec-fetch-site'];
    delete draft['sec-fetch-mode'];
    delete draft['sec-fetch-user'];
    delete draft['sec-fetch-dest'];
    delete draft['user-agent'];
    // Next.js에서 호출됐는지 기록한다.
    draft['powered-by-next'] = isServer;
  });
};

export default fetcher;
